<template>
  <!-- 发票申请 -->
  <div class="bill-handle">
    <div class="bill-title" @click="$router.go(-1)">
      添加发票信息
    </div>
    <div class="bill-tip">
      <div class="bill-font"><span> ！</span>电子发票与纸质发票具有同等法律效力，可支持报销入账；同时根据增值税管理办法要求，
        如需为企业开具增值税发票，需提供纳税人识别号或统一社会信用代码，否则该发票无法作为税收凭证。
      </div>
    </div>
    <div>
      <el-form class="formLabelAlign" :model="formLabelAlign" :rules="rules" ref="ruleForm" label-width="200px">
        <el-form-item label="发票类型">
          <el-select v-model="formLabelAlign.type">
            <el-option label="个人" value="0">
            </el-option>
            <el-option label="企业" value="1">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="抬头" prop="billHeader">
          <el-input v-model="formLabelAlign.billHeader"></el-input>
        </el-form-item> 
        <el-form-item label="税号" prop="billIdentifyNum" v-if="formLabelAlign.type == 1">
          <el-input v-model="formLabelAlign.billIdentifyNum"></el-input>
        </el-form-item>
        <el-form-item label="企业地址" v-if="formLabelAlign.type == 1">
          <el-input v-model="formLabelAlign.businessAddress"></el-input>
        </el-form-item>
        <el-form-item label="企业电话" v-if="formLabelAlign.type == 1">
          <el-input v-model="formLabelAlign.businessPhone"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" v-if="formLabelAlign.type == 1">
          <el-input v-model="formLabelAlign.billBankName"></el-input>
        </el-form-item>
        <el-form-item label="银行账号" v-if="formLabelAlign.type == 1">
          <el-input v-model="formLabelAlign.billBankAccount"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="billReceiverPhone">
          <el-input v-model="formLabelAlign.billReceiverPhone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="billReceiverEmail">
          <el-input v-model="formLabelAlign.billReceiverEmail"></el-input>
        </el-form-item>
        <!-- <el-form-item label="发票内容" v-if="formLabelAlign.type == 0">
          <el-input v-model="formLabelAlign.billContent"></el-input>
        </el-form-item> -->
        <el-form-item style="margin-top: 40px;text-align: center;">
          <el-button type="primary" @click="gotFormLabelAlign('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { httpPost } from "@/utils/httpRequest";

export default {
  name: "BillHandle",
  data() {
    return {
      labelPosition: 'right',
      formLabelAlign: {
        billBankAccount: '', //开户行账号
        billBankName: '', //开户行
        billContent: '', //发票内容
        billHeader: '', //发票抬头
        billIdentifyNum: '', //纳税人识别号
        billReceiverEmail: '', //收票人邮箱
        billReceiverPhone: '', //收票人电话
        businessAddress: '', //开户行账号
        businessPhone: '', //企业电话
        name: '', //企业名称
        type: '0', //发票类型0:个人及政府单位 1:企业
      },
      rules: {
        billBankAccount: [
          { required: true, message: '请输入开户行账号', trigger: 'blur' },
        ],
        billBankName: [
          { required: true, message: '请输入开户行', trigger: 'blur' },
        ],
        billHeader: [
          { required: true, message: '请输入发票抬头', trigger: 'blur' },
        ],
        billIdentifyNum: [
          { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
        ],
        billReceiverPhone: [
          { required: true, message: '请输入收票人电话', trigger: 'blur' },
        ],
        businessAddress: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        billReceiverEmail: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    gotFormLabelAlign(formLabelAlign) {
      this.$refs[formLabelAlign].validate((valid) => {
        if (valid) {
          // console.log(this.formLabelAlign)
          httpPost("/app/app-user-bill-msg/add",
            this.formLabelAlign,
            (res) => {
              // console.log(res)
              this.$message("添加成功")
              },
            (err) => {
               
            }
          )
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    }
  },
  created() {

  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.formLabelAlign{
  margin-top: 20px;
  padding-right: 200px;
  .el-input{
    width: 100%;
  }
}
.bill-handle {
  padding: 0 2rem;
  background: #FFFFFF;
   border-radius: 10px;
  height: 49.5rem;

  ::v-deep .el-radio {
    width: 16px;
    height: 16px;
    background: #FFFFFF;
  }

  ::v-deep .el-radio__inner {
    width: 14px;
    height: 14px;
    background: #ffffff;
    margin: 0 auto;
    border: 0.0625rem solid #1371E7;
    border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
  }

  ::v-deep .el-radio__inner::after {
    background-color: #1371E7;
    width: 0.5rem;
    height: 0.5rem;
  }

  .apply {
    width: fit-content;
    padding: 0 6.015%;
    height: 2rem;
    background: #1371E7;
    box-shadow: 0 0.25rem 0.375rem 0 rgba(19, 113, 231, 0.08);
    border-radius: 10px;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2rem;
    text-align: center;
    cursor: pointer;
    //margin-top: 20px;
  }

  ::v-deep .el-form-item {
    margin-bottom: 1.25rem;
    height: 2rem;
  }

  ::v-deep .el-form-item__label {
    font-size: 0.875rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }

  ::v-deep .el-input__inner {
    line-height: 2rem;
    height: 2rem;
  }

  .bill-tip {
    width: 100%;
    height: 4.25rem;
    background: #FFF5EC;
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
    border: 0.0625rem solid #FECCA0;
    text-align: left;
    margin-top: 1.25rem;
    display: flex;
    align-items: center;

    .bill-font {
      // height: 2.75rem;
      font-size: 0.875rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #3E454D;
      line-height: 1.375rem;
      padding: 0.75rem;

      span {
        width: 1rem;
        height: 1rem;
        line-height: 1.375rem;
        background: #F2994A;
        border-radius: 50%;
        margin-right: 0.375rem;
        padding-left: 0.375rem;
        color: white;
      }
    }
  }

  .bill-title {
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    font-size: 1.25rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    border-bottom: 0.0625rem solid #EDEFF2;
    cursor: pointer;
  }

  .bill-title::before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('~@/assets/img/pre/icon-arrowL-24.png');
    background-size: 100% 100%;
    margin-right: 0.625rem;
  }

}
</style>